// header
.header:has(.menu.show) {
	background: $green-new !important;
	.site-switcher .nav-link {
		color: $black !important;
	}
}

&.home,
&:has(.eyecatcher) {
	.header {
		position: absolute;
		background: transparent !important;
		box-shadow: none !important;
		@include media-breakpoint-down(md) {
			background: transparent !important;
		}
		.navbar-nav .nav-link {
			color: $white !important;
			@include media-breakpoint-down(md) {
				color: $black !important;
			}
		}
		.site-switcher .nav-link {
			color: $white !important;
		}
		div.logo {
			position: relative;
			width: 178px;
			@include media-breakpoint-down(md) {
				position: relative;
				width: 40%;
				top: unset;
				left: unset;
				margin: 0;
			}
		}
		&.stick {
			background: $green-new !important;
			position: fixed;
			top: 0;
			box-shadow: $shadow !important;

			div.logo {
				position: fixed;
				top: -5px;
				left: 1rem;
				width: 178px;
				@include media-breakpoint-down(md) {
					position: relative;
					width: 80px;
					top: unset;
					left: unset;
					margin: 0;
				}
			}
			div.partner-header {
			}

			.navbar-nav .nav-link {
				color: $black !important;
			}
			.menu {
				.dropdown-menu.show {
					background: $green-new !important;
				}
			}
			.bottom {
				background: none;
				padding-bottom: 5px;
			}
		}
		.top {
			height: 6vh;
			max-height: 6vh;
			.column {
				padding: 0 15px !important;
			}
		}
		.bottom {
			background: linear-gradient(rgba($black, 0.35), rgba(#000, 0) 100%);
		}
	}
}
.header {
	z-index: 1000;
	transition: 0.5s;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: $green-new;
	@include media-breakpoint-down(md) {
		background: transparent;
		&.stick {
			.top {
				display: none;
			}
			.bottom {
				.column {
					padding: 5px;
				}
				.navbar-toggler {
					padding: 0;
				}
				.menu-toggle {
					display: block !important;
					order: -1;
				}
			}
		}
		.top {
		}
	}
	.navbar-nav .nav-link {
		color: $black !important;
	}
	div.logo {
		position: fixed;
		top: 1rem;
		left: 1rem;
		width: 200px;
		@include media-breakpoint-down(md) {
			position: relative;
			width: 80px;
			top: unset;
			left: unset;
			margin: 0;
		}
	}
	&.sticky {
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;
				padding-top: 15px;
				padding-bottom: 0;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					align-items: center;
					padding-top: 3px;
				}

				@extend .navbar-expand-xl;
			}
		}
		&.top {
			background: $green-new;
			.column {
				padding: 15px;
				@include media-breakpoint-down(md) {
					padding: 5px;
				}
			}
			// searchbox
			div.searchbox {
				position: relative;
				margin-left: auto;
				margin-right: 0.5rem;
				display: none;
				transition: 0.5s ease;
				&.show {
					display: block;
					width: auto;
					opacity: 1;
					transition: 0.5s ease;
					@starting-style {
						opacity: 0;
						display: block;
					}
					input {
						height: 90%;
						font-size: 18px;
						font-weight: bold;
					}
					@include media-breakpoint-down(lg) {
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						transform: translateY(99%);
						z-index: 1000;
						background: $green-new;
						input {
							height: 6vh;
							font-size: 18px;
							font-weight: bold;
						}
					}
					transition: 0.5s ease;
				}
			}
			.title {
				@include media-breakpoint-down(lg) {
					order: 2;
					margin-right: 0;
					margin-left: 1rem;
				}
				.search-icon {
					cursor: pointer;
					margin-right: 1rem;
					margin-top: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					@include media-breakpoint-down(lg) {
						margin-right: 0;
					}
					&::after {
						content: "\f002";
						font-family: $font-awesome;
						font-size: 16px;
					}
				}
			}
		}
		&.bottom {
			.column {
				align-items: center;
				padding: 15px;
				@include media-breakpoint-down(md) {
					.menu-toggle {
						display: none;
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-right: auto;
		.navbar-toggler {
			i {
				color: $green-darker;
				font-size: 22px;
				transform: rotate(-360deg);
				transition: 0.45s ease;
			}
			span {
				margin-left: 0.35rem;
				font-family: $font-family-primary;
			}
			&[aria-expanded="true"] {
				i {
					transform: rotate(360deg);
					transition: 0.45s ease;
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 138px;
		margin: 20px 5rem 25px 1rem;
		transition: 0.5s ease;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(md) {
			order: -1;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(md) {
			order: 2;
			.navbar-nav {
				padding: 3rem 1rem;
				.nav-item {
					border-bottom: 1px solid $brown;
					.nav-link {
						font-family: $font-family-secondary;
						color: $green-dark;
						font-size: 18px;
					}
					&.active {
						.nav-link {
							color: $blue !important;
							font-weight: 600;
						}
					}
					.dropdown-menu {
						position: static !important;
						border: 0 !important;
						box-shadow: none !important;
						margin: 0 !important;
						padding: 0 !important;
						transform: none !important;
						background: none !important;
						&::after,
						&::before {
							display: none;
						}
						.nav-item {
							border-bottom: 0;
						}
						.nav-link {
							font-size: 16px;
							font-family: $font-family-primary;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			transform: translateY(50%);
			.navbar-nav {
				margin-left: auto;
				margin-right: auto;
				margin-top: auto;
				margin-bottom: auto;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				height: max-content;
				width: max-content;
				display: flex;
				justify-content: center;
				gap: 1.5rem;
				.nav-item {
					.nav-link {
						color: $white;
						font-family: $font-family-primary;
						font-weight: 600;
						font-size: 16px;
					}
					&:hover {
						.nav-link {
							text-decoration: underline;
							text-underline-offset: 0.8rem;
						}
					}
					.dropdown-menu.show {
						border: 0;
						background: $green-new;
						box-shadow: none;
						margin-top: 1.3rem;
						border-radius: 6px;
						min-width: 15vw;
						transform: none !important;
						top: 6vh !important;
						left: -1.5rem !important;
						padding: 1.5rem;
						.nav-link {
							text-decoration: none;
							color: $black !important;
							text-underline-offset: 5px;
							&:hover {
								text-decoration: underline;
								text-underline-offset: 5px;
							}
						}
						&::after,
						&::before {
							left: 2rem;
							right: unset;
							border-left: 12px solid transparent;
							border-right: 12px solid transparent;
							border-bottom: 12px solid $green-new;
						}
					}
				}
			}
		}
	}

	// secondary menu
	div.menu-secondary {
		margin-right: 1rem;
		ul {
			display: flex;
			gap: 0.85rem;
			li {
				font-size: 12px;
				font-weight: 500;
			}
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	// book-btn
	div.book-btn {
		@include media-breakpoint-down(md) {
			order: -1;
		}
	}
	.book-btn {
		a {
			position: relative;
			text-decoration: none;
			color: $white;
			font-size: 14px;
			font-weight: bold;
			.caption {
				background: url("/images/book-btn-paint.svg");
				background-size: 100% 100%;
				/* Schaal zowel horizontaal als verticaal naar 100% */
				background-repeat: no-repeat;
				padding: 1rem 2rem;
			}
		}
		@include media-breakpoint-down(md) {
			font-size: 12px;
			margin-left: auto;
			margin-right: 0em;
			a {
				margin-right: 0.35rem;
			}
		}
	}

	// site-switcher
	.site-switcher {
		.nav-link.dropdown-toggle {
			display: flex !important;
			flex-flow: row nowrap !important;
			align-items: center;
			color: $white;
			font-weight: 600;
			&::after {
				color: $white;
				display: none;
			}
			&:hover {
				border: none !important;
				box-shadow: none !important;
			}
		}
		.dropdown-menu {
			box-shadow: none !important;
			border: none !important;
			background: $blue-light !important;
			transform: translate(-6px, 35px) !important;
			left: 0 !important;
		}
	}

	div.partner-header {
		@include media-breakpoint-down(md) {
			margin-left: auto;
		}

		a {
			display: flex;
			flex-flow: row !important;
			align-items: center;
			&:hover {
				text-decoration: none;
			}
		}
		.list-item-title {
			display: none;
		}
		.caption {
			order: -1;
			.list-item-short-description {
				font-size: 12px;
				color: #4c893c;
			}
		}
		.list-item-suffix {
			display: none;
		}
		img {
			max-width: 115px;
		}
	}
}

// templates
&.paviljoen-page {
	.container-fluid.top,
	&:has(.eyecatcher) .header.stick,
	.dropdown-menu.show,
	&:has(.eyecatcher) .header.stick .menu .dropdown-menu.show {
		background: #c9beb5 !important;
		&::before,
		&::after {
			border-bottom-color: #c9beb5 !important;
		}
	}
	.navbar-nav .nav-item.active .nav-link,
	.navbar-nav .nav-item:hover .nav-link {
		color: #c9beb5 !important;
	}
	.stick .navbar-nav .nav-item.active .nav-link,
	.stick .navbar-nav .nav-item:hover .nav-link {
		color: #000 !important;
	}
	@include media-breakpoint-down(lg) {
		&.stick .bottom,
		&:has(.menu.show) .bottom {
			background: #c9beb5 !important;
		}
	}
}
&.watersport-page {
	.container-fluid.top,
	&:has(.eyecatcher) .header.stick,
	.dropdown-menu.show,
	&:has(.eyecatcher) .header.stick .menu .dropdown-menu.show {
		background: #bad2d3 !important;

		&::before,
		&::after {
			border-bottom-color: #bad2d3 !important;
		}
	}
	.navbar-nav .nav-item.active .nav-link,
	.navbar-nav .nav-item:hover .nav-link {
		color: #bad2d3 !important;
	}
	.stick .navbar-nav .nav-item.active .nav-link,
	.stick .navbar-nav .nav-item:hover .nav-link {
		color: #000 !important;
	}
	.header.stick .navbar-nav .nav-item.active .nav-link,
	.header.stick .navbar-nav .nav-item:hover .nav-link {
		color: $black !important;
	}
	@include media-breakpoint-down(lg) {
		&.stick .bottom,
		&:has(.menu.show) .bottom {
			background: #bad2d3 !important;
		}
	}
}

&.home .footer {
	padding-top: 6vh;
	position: relative;
	&::before {
		content: "";
		background: url("/images/footer-element.svg");
		background-size: 120%;
		background-position: left -50px top;
		background-repeat: no-repeat;
		position: absolute;
		top: -20vh;
		left: 0;
		width: 100%;
		height: 25vh;
		pointer-events: none;
	}
	.bundle-section,
	.footer-links::before {
		display: none;
	}
}
// footer
.footer {
	background: $green-darker;
	margin-bottom: 0;
	padding-bottom: 1px;
	position: relative;
	* {
		color: $white;
	}
	section {
		margin: 2.5rem auto;
		&.footer-links {
			position: relative;
			z-index: 2;
			padding-top: 9vh !important;
			margin-top: 0 !important;
			&::before {
				content: "";
				background: url("/images/footer-element.svg");
				background-size: 120%;
				background-position: left -50px top;
				background-repeat: no-repeat;
				position: absolute;
				top: -3vh;
				left: 0;
				width: 100%;
				height: 6vh;
				pointer-events: none;
				transform: scaleY(-1);
			}

			.column {
				max-width: 22%;
				flex-basis: 22%;
				&.four {
					max-width: 33%;
					flex-basis: 33%;
					h6,
					h5,
					h4,
					h3 {
						font-family: $font-family-primary;
					}
					div.social-media {
						ul {
							display: flex;
							gap: 0.85rem;
							align-items: center;
							flex-flow: row;
							justify-content: flex-start;
							a {
								&:hover {
									i {
										transform: scale(1.05);
										transition: 0.35s;
									}
								}
							}
							li {
								i {
									font-size: 30px;
									color: $white;
									transition: 0.35s;
								}
								span {
									display: none;
								}
							}
						}
					}
					@include media-breakpoint-down(md) {
						order: -1;
						max-width: 100%;
						flex-basis: 100%;
					}
					.btn {
						border-radius: 38px;
						background: $green-darker;
						padding: 0.5rem 1.75rem;
						border-color: $white;
						&:hover {
							background: $white;
							color: $green-darker;
						}
					}
				}
				@include media-breakpoint-down(md) {
					max-width: 50%;
					flex-basis: 50%;
					margin-bottom: 6vh;
				}
			}
			.footer-list {
				h5 {
					display: none;
				}
				.link {
					font-size: 16px;
					font-family: $font-family-primary;
					font-weight: 600;
				}
			}
		}
		&.footer-contact {
			.container .container-holder .column {
				display: flex;
				flex-flow: row;
				gap: 3rem;
				@include media-breakpoint-down(md) {
					flex-flow: column;
				}
				.footer-text {
					p {
						font-weight: 500;
					}
					a {
						text-decoration: underline;
						font-weight: 500;
					}
				}
			}
			.logo {
				img {
					width: 213px;
				}
			}
		}
		&.footer-socket {
			margin: 0 auto !important;
			background: $green-darker;
			padding: 1rem 0;
			.list {
				display: flex;
				justify-content: center;
				font-weight: 200;
				gap: 1.2rem;
				li,
				a {
					color: $white;
				}
				@include media-breakpoint-down(md) {
					justify-content: flex-start;
					li {
						font-size: 12px;
					}
				}
			}
		}
	}

	// bundle-outro-section
	.bundle-section {
		background: $body-bg;
		padding-bottom: 6vh;
		padding-top: 0vh;
		position: relative;
		z-index: 3;
		margin-bottom: 0;
		.title-container {
			.container-holder {
				max-width: 750px;
				margin: 6vh auto 0 auto;
				text-align: center;

				h2 {
					text-transform: uppercase;
					font-size: 24px;
					font-weight: 600;
					color: $white;
					font-family: $font-family-primary !important;
					line-height: 20px;
					color: $green-dark;
				}

				div.title:nth-child(2) {
					margin-bottom: 3.5rem;
				}

				h1 {
					color: $green-dark;
					font-family: $font-family-tertirary;
					font-size: 48px;
					font-weight: 200;
					position: relative;
					display: block;

					&::after {
						content: url("/images/underline-green-dark.svg");
						position: absolute;
						height: 6px;
						width: 30%;
						left: 50%;
						bottom: 1rem;
						transform: translateX(-50%);
					}
				}
			}
		}
		.price-card {
			margin-top: 6vh;
			.grid-items {
				.item {
					display: none;
					&:nth-child(1) {
						display: flex !important;
						max-width: 80%;
						flex-basis: 80%;
						@include media-breakpoint-down(md) {
							max-width: 100%;
							flex-basis: 100%;
						}
						.card {
							border: 0;
							overflow: visible;
							.card-image {
								display: none;
							}
							.card-title,
							.card-subtitle,
							.card-description {
								text-align: center;
							}
							.card-title {
								font-size: 36px;
								font-weight: 600;
							}
							.card-subtitle {
								font-family: $font-family-primary !important;
								font-size: 18px;
								font-weight: 600;
							}
							.card-buttons {
								display: flex;
								justify-content: center;
								.card-btn {
									all: unset;
									font-size: 24px !important;
									text-transform: none;

									box-shadow: none !important;
									font-family: $font-family-secondary !important;
									padding: 1.25rem !important;

									color: $white;
									position: relative;
									z-index: 1;
									display: flex;
									align-items: center;
									font-weight: 600;
									transition: 0.35s ease;
									&:hover {
										transform: rotate(-10deg);
										transition: 0.35s ease;
									}
									&:before {
										content: url("/images/button-square.svg");
										z-index: -1;
										left: 50%;
										transform: translate(-50%, 10%);
										position: absolute;
									}
								}
							}
							.card-description {
								font-size: 16px;
								font-weight: 300;
							}
						}
					}
				}
			}
		}
	}
}

&.watersport-page .footer .bundle-section {
	background: #f2f6f7 !important;
}
&.watersport-page .footer .footer-links::before {
	content: "";
	background: url("/images/footer-element-watersport.svg");
	background-size: 120%;
	background-position: left -50px top;
	background-repeat: no-repeat;
	position: absolute;
	top: -3vh;
	left: 0;
	width: 100%;
	height: 6vh;
	pointer-events: none;
	transform: scaleY(-1);
}

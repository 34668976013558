&:has(.eyecatcher) .mini-sab {
	margin-top: -6vh;
	@include media-breakpoint-down(md) {
		max-height: max-content;
		margin-top: -24vh;
	}
}

&.accommodation-category-overview {
	.mini-sab {
		display: none;
	}
}

&.home .mini-sab {
	margin-top: -12vh;
	@include media-breakpoint-down(md) {
		max-height: max-content;
		margin-top: -20vh;
	}
}
.mini-sab {
	margin-top: 20vh;
	max-height: 18vh;
	position: relative;
	z-index: 999;
	@include media-breakpoint-down(md) {
		max-height: max-content;
		margin-top: 6vh;
	}
	.container {
		max-width: 90vw !important;
		width: fit-content;

		@include media-breakpoint-down(md) {
			max-width: 80%;
			margin: 6vh auto;
		}

		.container-holder {
			position: relative;
			padding: 6vh 4.5rem;
			background-image: url("/images/sab-background.svg");
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center;

			@include media-breakpoint-down(md) {
				background-image: url("/images/sab-mobile.svg");
				padding: 3rem 1rem;
			}

			.column {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-flow: row;
				gap: 1rem;

				@include media-breakpoint-down(md) {
					flex-flow: column;

					.caption span {
						text-align: center;
					}
				}

				div.search-book-text {
					max-width: 190px;
					ul li .caption {
						display: flex;
						flex-flow: column;
						.list-item-title {
							color: $white;
							font-family: $font-family-secondary;
							font-weight: 600;
							font-size: 24px;
							line-height: 22px;
						}
						.list-item-short-description {
							color: $white !important;
						}
					}
					@include media-breakpoint-down(md) {
						max-width: 90%;
					}

					.list-item-suffix {
						display: none;
					}
				}
				.filters {
					display: flex;
					justify-content: center;
					.filter-element {
						max-width: unset !important;
						.placeholder {
							padding-right: 1rem;
						}
						.input {
							display: flex !important;
							align-items: center !important;
							gap: 1rem !important;

							.badge {
								margin-right: 0.5rem;
							}
						}
						.current-item {
							height: unset !important;
						}
					}
				}
			}
		}
	}
	.dp__arrow_bottom {
		display: none;
	}

	.mini-search-book {
		width: 100%;
	}
}

&.accommodation-detail .mini-sab {
	display: none;
}

.faq-overview {
	.faq-items {
		.faq-item {
			.faq-question {
				border: none !important;
				border-bottom: 1px solid $black !important;
				display: flex;
				flex-flow: row;
				align-items: center;
				justify-content: space-between;
				padding-left: 0 !important;
				.faq-icon {
					all: unset !important;
					position: relative !important;
					order: 1;
					i {
						&::before {
							content: "\2b";
							transition: 0.35s ease;
							font-size: 36px;
						}
					}
				}
				.faq-category {
					margin-right: auto !important;
					* {
						order: -1;
						font-size: 18px !important;
						font-family: $font-family-primary !important;
					}
				}
			}
			&.active {
				.faq-icon i {
					&::before {
						content: "\f068";
						transform: rotate(360deg);
						transition: 0.35s ease;
						font-size: 18px;
					}
				}
			}
			.faq-answer {
				padding-left: 0 !important;
				border: 0 !important;
			}
		}
	}
}

// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// .lead-section + .content-section,
// .lead-section + .bundle-overview-section {
// 	margin-top: calc(-6vh + 30px);
// }

.home-intro-section {
	margin: 6vh auto;
	.container {
		.container-holder {
			max-width: 70%;
			margin: auto;
			.column {
				text-align: center;
			}
		}
	}
}

.usp-intro-section {
	margin: 6vh auto;
	padding-bottom: 26vh;
	.wysiwyg {
		max-width: 100% !important;
		ul {
			padding: 0;
			width: 95%;
			margin: auto;
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include media-breakpoint-down(md) {
				flex-flow: column;
				align-items: center;
				gap: 1.2rem;
			}
			li {
				display: flex;
				gap: 0.5rem;
				margin: 0;
				color: $green-darker;
				font-family: $font-family-secondary;
				font-size: 18px;
				font-weight: 600;

				justify-content: center;
				align-items: center;
				&::before {
					content: "\f00c";
					font-family: $font-awesome;
				}
			}
		}
	}
}

// home section
.home-section {
	background: $green-new-2;
	padding: 3vh 0 32vh 0;
	position: relative;

	&::before {
		content: "";
		height: 50%;
		width: 100%;
		background: url("/images/section-one-top.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		top: 0;
		left: 0;
		position: absolute;
		pointer-events: none;
		@include media-breakpoint-down(md) {
			width: 102%;
			max-width: 100vw;
		}
	}

	&::after {
		content: "";
		height: 50%;
		width: 100%;
		background: url("/images/section-one-top.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		transform: scaleY(-1);
		bottom: -1px;
		left: 0;
		position: absolute;
		pointer-events: none;
	}
	.cards-container {
		margin-top: -24vh;
		.grid-items {
			.item {
				&:nth-child(n + 4) {
					display: none;
				}
				.card {
					@extend .default-card;
					.card-title {
						color: $black;
					}
					.card-buttons {
						margin-top: 6vh;
						.card-btn {
							font-size: 16px;
						}
					}
					&:hover {
						.card-title {
							color: $green-darker;
						}
					}
				}
			}
		}
	}

	.title-container {
		.container-holder {
			max-width: 750px;
			margin: 6vh auto 0 auto;
			text-align: center;

			.title {
				color: $white;
				font-family: $font-family-tertirary;
				font-size: 48px;
				font-weight: 200;
				position: relative;

				.title::after {
					content: url("/images/underline-white.svg");
					position: absolute;
					height: 6px;
					width: 40%;
					left: 50%;
					bottom: -0.05rem;
					transform: translateX(-50%);
				}
			}
		}
	}

	.price-container {
		margin-top: 2.85rem;
		.container-holder {
			max-width: 700px;
			margin: 1rem auto;

			.description {
				max-width: 85%;
				text-align: center;
				margin: auto;
			}

			.title {
				font-size: 36px;
				text-align: center;
			}

			div.price-link {
				max-width: 85%;
				display: flex;
				justify-content: center;
				text-align: center;
				margin: auto;
				margin-top: 1rem;

				a {
					@extend .price-btn;
					margin: auto;
				}
			}
		}
	}

	&.two {
		padding-bottom: 32vh;
		background: $body-bg;
		z-index: 200;
		.title-container .title .title {
			color: $black !important;
			position: relative;
			&::after {
				content: url("/images/underline-black.svg");
				position: absolute;
				height: 6px;
				width: 40%;
				left: 50%;
				bottom: -0.05rem;
				transform: translateX(-50%);
			}
		}
	}
	&.three {
		background: $body-bg;
		padding-bottom: 12vh;
		padding-top: 0;
		z-index: 200;
		margin-bottom: 3vh;
	}
}

&:not(:has(.home-section.three)) {
	.home-section.two {
		padding-bottom: 3vh !important;
	}
}

// paviljoen-page sections
.paviljoen-reservation-section {
	background: #f1eeec;
	padding-bottom: 18vh !important;
	div.reservation-link {
		align-content: center;
		text-align: center;
		transform: translateY(-50%);
		z-index: 900;
		@include media-breakpoint-down(lg) {
			transform: translateY(-25%);
		}
		a {
			position: relative;
			text-decoration: none;
			color: $white;
			font-size: 14px;
			font-weight: bold;
			background: url("/images/book-btn-paint.svg");
			background-size: cover;
			display: block;
			background-repeat: no-repeat;
			padding: 1rem 2rem;
			width: 300px !important;
			margin: auto;
			&::after {
				content: "\f054";
				margin-left: 1rem;
				font-family: $font-awesome;
				transition: 0.5s ease;
			}
			&:hover {
				&::after {
					margin-left: 1.5rem;
					transition: 0.5s ease;
				}
			}
		}
	}
	.title {
		margin-top: 0.5rem;
		margin-left: auto;
		margin-right: auto;
		@include media-breakpoint-down(lg) {
			text-align: center;
		}
	}
	.description {
		margin-top: 0.5rem;
		margin-left: auto;
		margin-right: auto;
		@include media-breakpoint-down(lg) {
			text-align: center;
		}
	}
	.usp-list {
		margin: 3vh auto;
		@include media-breakpoint-down(lg) {
			margin: 3vh 1rem;
		}
		ul {
			display: flex;
			flex-flow: row;
			justify-content: center;
			gap: 1.5rem;
			list-style: none;
			@include media-breakpoint-down(lg) {
				flex-flow: column;
				padding: 0;
				max-width: 100%;
				width: 80%;
				text-align: center;
				margin: auto;
			}
			li {
				font-family: $font-family-secondary;
				font-weight: 600;
				font-size: 18px;
				color: #584b40;
				&::before {
					content: "\f00c";
					font-family: $font-awesome;
					margin-right: 0.5rem;
				}
			}
		}
	}
}

.paviljoen-arrangements-section {
	background: #c9beb5;
	position: relative;
	padding-bottom: 6vh;
	&::after {
		content: "";
		position: absolute;
		left: 0;
		top: -4.5vh;
		transform: scaleY(-1);
		width: 100%;
		height: 6vh;
		background: url("/images/paviljoen-brown-element.svg");
		background-size: cover;

	}
	.slider {
		margin-top: -12vh;
		.owl-nav {
			@include media-breakpoint-down(lg) {
				display: none !important;
			}
		}
		.card {
			@extend .default-card;
			.card-title {
				color: $black;
				font-size: 30px;
			}
		}
	}
	.title-container {
		margin-top: 6vh;
		.title .title {
			color: $white !important;
			position: relative;
			font-family: "lemontuesday";
			font-size: 48px;
			text-align: center;
			font-weight: normal;
			&::after {
				content: url("/images/underline-white.svg");
				position: absolute;
				height: 6px;
				width: 25%;
				left: 50%;
				bottom: -0.05rem;
				transform: translateX(-50%);
			}
		}
		.subtitle {
			margin-top: 2.5rem;
			text-align: center;
			font-size: 36px;
			font-family: $font-family-secondary;
		}
		.description {
			text-align: center;
			max-width: 75%;
			margin: auto;
		}
		.special-link {
			text-align: center;
			a {
				position: relative;
				text-decoration: none;
				color: $white;
				font-size: 14px;
				font-weight: bold;
				background: url("/images/book-btn-paint.svg");
				background-size: cover;
				display: block;
				background-repeat: no-repeat;
				padding: 1rem 2rem;
				width: 200px !important;
				margin: auto;
			}
		}
	}
}

.paviljoen-content-section {
	.section {
		padding: 6vh 0 9vh 0;
		background: #c9beb5;
		.container {
			.one {
				img {
					border-radius: 3px;
					box-shadow: 0px 3px 6px rgba(#000, 0.29);
				}
			}
			.two {
				display: flex;
				flex-flow: column;
				justify-content: center;
				p {
					font-size: 16px;
				}
				h2 {
					font-weight: 500;
				}
				ul {
					list-style: none;
					padding: 0;
					li {
						&:before {
							content: "\f054";
							font-family: $font-awesome;
							margin-right: 0.65rem;
							font-weight: 600;
						}
					}
				}
				a {
					font-weight: bold;
					&:after {
						content: "\f054";
						font-family: $font-awesome;
						margin-left: 0.65rem;
						font-weight: 600;
					}
				}
			}
		}
		&:nth-child(even) {
			background: $body-bg;
			position: relative;
			&::before {
				content: "";
				position: absolute;
				top: -3vh;
				left: 0;
				background-image: url("/images/paviljoen-content-section.svg");
				background-size: 100%;
				background-repeat: no-repeat;
				width: 100%;
				height: 6vh;
			}
			.container .container-holder {
				flex-flow: row-reverse wrap;
			}
		}
	}
}

// watersport template
&.watersport-page {
	.paviljoen-reservation-section {
		background: #f2f6f7;
		&::after {
		}
	}
	.paviljoen-arrangements-section {
		background: #c9dbdc;
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: -4.5vh;
			transform: scaleY(-1);
			width: 100%;
			height: 6vh;
			background: url("/images/watersport-first-element.svg");
			background-size: cover;
		}
	}
	.paviljoen-content-section {
		background: #f2f6f7;
		overflow: hidden;
		position: relative;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: -4vh;
			transform: scaleY(-1) scaleX(1.5);
			width: 100%;
			height: 6vh;
			background: url("/images/watersport-second-element.svg");
			background-size: cover;
			z-index: 900;
		}

		.carousel-container {
			position: relative;
			.column {
				padding-left: 0;
				padding-right: 0;
				padding-bottom: 16vh;
				.owl-carousel {
					.owl-caption-holder {
						.owl-container {
							height: 100%;
							position: relative;
							display: flex;
							align-items: flex-end;
							z-index: 900;
							&::before {
								content: "";
								position: absolute;
								left: 0;
								bottom: -4vh;
								transform: scaleX(1.5);
								width: 100vw;
								height: 6vh;
								background: url("/images/watersport-first-element.svg");
								background-size: cover;
								z-index: 700;
							}
						}

						position: relative;
						width: 100%;
						height: 100%;
						.owl-caption {
							background: #fff;
							box-shadow: 0px 3px 6px rgba(#000, 0.29);
							padding: 1.2rem;
							border-radius: 4px;
							max-width: 25vw;
							margin-bottom: -10vh;
							text-align: left;
							z-index: 900;
							@include media-breakpoint-down(lg) {
								text-align: center;
								max-width: 100%;
							}
							.owl-title {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								font-size: 70px;
								font-family: "lemontuesday";
								font-weight: normal;
								text-align: center;
								width: 60vw;
								@include media-breakpoint-down(lg) {
									text-align: center;
									font-size: 32px;
									width: 90vw;
								}
							}
							.owl-subtitle {
								font-family: $font-family-secondary;
								color: $black;
								font-weight: 600;
								font-size: 30px;
							}
							.owl-description {
								color: $black;
								font-size: 16px;
								font-weight: 300;
								max-width: 80%;
								margin: 1rem auto 1rem 0;
								display: block;
								max-height: 99px;
								overflow: hidden;
								white-space: wrap;
								text-overflow: ellipsis;
							}
							.owl-btn {
								background: none;
								padding: 0;
								color: $black;
								border: 0;
								box-shadow: none;
								font-size: 16px;
								font-weight: bold;
								&::after {
									content: "\f054";
									font-family: $font-awesome;
									font-size: 16px;
									margin-left: 0.5rem;
								}
							}
						}
					}
					.owl-stage-outer {
						overflow: visible;
					}
					.owl-dots {
						display: none;
					}
					.owl-nav {
						@include media-breakpoint-down(lg) {
							display: none !important;
						}
					}
					.owl-item {
						min-height: 65vh;
						.item {
							padding: 0;
						}
					}
				}
			}
		}
		.faq-container {
			.title .title {
				position: relative;
				font-family: "lemontuesday";
				font-size: 48px;
				text-align: center;
				font-weight: normal;
				margin-bottom: 6vh;
				@include media-breakpoint-down(lg) {
					font-size: 32px;
				}

				&::after {
					content: url("/images/underline-black.svg");
					position: absolute;
					height: 6px;
					width: 25%;
					left: 50%;
					bottom: -0.05rem;
					transform: translateX(-50%);
				}
			}
			.page-block.faq-category {
				max-width: 70%;
				width: 70%;
				margin: auto;
				@include media-breakpoint-down(lg) {
					width: 100%;
					max-width: 100%;
				}
			}
			.faq-question .page-block.faq-category,
			.faq-answer .page-block.faq-category {
				max-width: 100%;
				width: 100%;
				h3 {
					font-size: 16px !important;
				}
			}
		}
	}
}

// lead-section
// =========================================================================

.lead-section {
	margin: 6vh 0 0vh 0;
	@include media-breakpoint-down(md) {
		margin: 6vh auto !important;
	}

	.container-one-column {
		.container-holder {
			margin: 6vh auto;
			text-align: center;
			h3.subtitle,
			h5 {
				text-transform: uppercase;
				font-size: 24px;
				font-weight: 600;
				color: $green-dark;
				font-family: $font-family-primary !important;
				line-height: 20px;
			}

			div.title:nth-child(2) {
				margin-bottom: 3.5rem;
			}

			.title,
			.page-title,
			.page-title *,
			.title * {
				color: $green-dark;
				font-family: $font-family-tertirary;
				font-size: 60px;
				font-weight: 200;
				position: relative;
				margin-bottom: 3vh;
				.title::after,
				h1::after {
					content: url("/images/underline-green-dark.svg");
					position: absolute;
					height: 6px;
					width: 40%;
					left: 50%;
					bottom: 1rem;
					transform: translateX(-50%);
				}
				h1::after {
					width: 20%;
					bottom: 1.5rem;
				}
			}
			.info .wysiwyg {
				margin-top: 8vh;
			}
			p {
				font-size: 20px;
			}
			&:has(.subtitle) {
				.title {
					margin-bottom: 3.5vh !important;
				}
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		max-width: 750px;
		margin: auto;
		.properties {
			margin-top: 9vh;
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
				font-weight: 600;
				font-size: $h5-font-size;
			}

			.icon {
				margin-right: 10px;
				background: $green-secondary;
				padding: 5px;
				color: $white;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}

	// title fix
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	background: $green;
	position: relative;
	padding-top: 6vh;
	padding-bottom: 12vh;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 50%;
		pointer-events: none;
		background: url("/images/bundle-overview-top.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		@include media-breakpoint-down(md) {
			height: 12vh;
			background-size: cover;
		}
	}
	&::after {
		content: "";
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: 50%;
		pointer-events: none;
		background: url("/images/bundle-overview-top.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		transform: scaleY(-1);
	}

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}

	.faq-container {
		margin: 6vh auto;
		max-width: 770px;
	}
	.grid-items {
		margin-top: -6vh !important;
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}

&.home .notice-bar {
	display: flex !important;
	position: absolute;
	right: 2.5rem;
	top: 60vh;
	width: 300px;
	aspect-ratio: 1/1;
	background: url("/images/notice-bg.svg");
	background-size: 100%;
	background-repeat: no-repeat;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	.notice-bar-content {
		max-width: 232px;
		padding: 0.5rem;
		display: flex;
		flex-flow: column;
		position: relative;
		.notice-bar-content-description {
			h6,
			h5,
			h4,
			h3,
			h2,
			h1 {
				color: $white !important;
			}
			color: $white !important;
			.btn {
				width: 100%;
				text-transform: none;
			}
		}
		.notice-bar-close {
			order: -1;
			position: absolute;
			right: 0;
			top: -1ch;
			transform: translateY(-100%);
			.notice-bar-close-title {
				display: none;
			}
			.notice-bar-close-icon {
				color: $white;
				font-size: 18px;
			}
			&:hover {
				.notice-bar-close-icon i {
					transform: rotate(360deg);
					transition: 0.45s ease;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		display: none !important;
	}
}

// container
.container {
	&.container-small {
		max-width: 540px;
	}

	&.container-medium {
		max-width: 720px;
	}

	&.container-intro {
		max-width: 720px;
		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		max-width: 720px;
		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	box-shadow: $shadow;
	border: 0;

	.card-image {
		position: relative;
		img {
		}
		&::after {
			content: "";
			position: absolute;
			top: 1px;
			left: -5px;
			width: 105%;

			height: 100%;
			background: url("/images/card2.svg") !important;
			background-position: bottom !important;
			background-repeat: no-repeat !important;
			background-size: 100% !important;
			pointer-events: none !important;
			margin: 0 1px;
		}
	}
	.card-body {
		padding: 1.5rem;
	}

	.card-title {
		font-size: 30px;
		font-family: $font-family-secondary;
		color: $green-new;
		@include media-breakpoint-down(md) {
			font-size: 24px;
		}
	}
	.card-subtitle {
		font-size: 16px;
		font-family: $font-family-primary;
		text-transform: uppercase;
	}

	.card-btn {
		padding: 0;
		border: 0;
		background: none;
		color: $black;
		box-shadow: none;
		font-size: 18px;
		text-transform: none;
		font-family: $font-family-primary;
		display: flex;
		flex-flow: row;
		max-width: max-content;
		align-items: center;
		gap: 0.5rem;
		transition: 0.35s ease;

		&::after {
			content: "\f054";
			font-family: $font-awesome;
			font-size: 14px;
		}

		&:hover {
			&::after {
				margin-left: 0.35rem;
				transition: 0.35s ease;
			}
		}
	}
}

// selection
* {
	&::selection {
		background: $green;
		color: $white;
	}
}

// templates
&.watersport-page {
	background: #f2f6f7 !important;
}

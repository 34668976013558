// eyecatcher
section.eyecatcher {
	position: relative;
	z-index: 900;
	.owl-item {
		max-height: 100%;
	}
}

.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 66vh;
	filter: brightness(0.9);
	max-height: 66vh;
	@include media-breakpoint-down(md) {
		height: 35vh;
		margin-top: 3vh;
	}

	.owl-dots,
	.owl-nav,
	.owl-description,
	.owl-btn {
		display: none;
	}
}

section.eyecatcher.large {
	position: relative;
	overflow: hidden;
	.eyecatcher-video {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 100%;
		z-index: -1;
		padding: 0;
		.container-holder {
			height: 100%;
		}
		.video-frame {
			height: 100%;
			iframe {
				width: 100%;
				height: 100%;
				object-fit: cover;
				aspect-ratio: 16/9;
				filter: brightness(0.85);
			}
		}
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 90vh;
	min-height: 540px;
	max-height: 1080px;
	margin-top: 0;
	filter: brightness(1.1);
	@include media-breakpoint-down(md) {
		height: 45vh;
	}
	.owl-dots,
	.owl-nav,
	.owl-description,
	.owl-btn {
		display: none;
	}

	.owl-caption,
	.owl-caption-holder {
		height: 100%;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		z-index: 980;

		.owl-title {
			font-family: $font-family-tertirary;
			font-size: 90px;
			font-weight: 400 !important;
			@include media-breakpoint-down(md) {
				font-size: 40px;
				margin-top: 16vh;
			}
		}
		.owl-subtitle {
			font-size: 36px;
			text-transform: uppercase;
			font-weight: 600;
			font-family: $font-family-primary !important;
			@include media-breakpoint-down(md) {
				font-size: 18px;
			}
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: black;
			z-index: -1;
			opacity: 0.15;
		}
	}
}

&.paviljoen-page section.eyecatcher {
	position: relative !important;
	filter: unset;
	&::before {
		content: "";
		position: absolute;
		bottom: -4.5vh;
		left: 0;
		width: 100%;
		height: 6vh;
		background-image: url("/images/Path-154.svg");
		background-repeat: no-repeat;
		background-size: cover;
		z-index: 1000;
		@include media-breakpoint-down(lg) {
			bottom: -5.5vh;
			transform: scaleX(1.2);
			background-size: contain;
		}
	}
}

&.watersport-page section.eyecatcher {
	position: relative !important;
	filter: unset;

	&::before {
		content: "";
		position: absolute;
		bottom: -4.5vh;
		left: 0;
		width: 100%;
		height: 6vh;
		background-image: url("/images/Path-154-WS.svg");
		background-repeat: no-repeat;
		background-size: cover;
		z-index: 1000;

		@include media-breakpoint-down(lg) {
			bottom: -5.5vh;
			transform: scaleX(1.2);
			background-size: contain;
		}
	}
}

// btn
.btn {
	border: 0;
	border-radius: 35px;
	font-weight: 700;
	padding: 10px 20px;
	&:hover {
		filter: brightness(0.9);
	}
	// btn-primary
	&.btn-primary {
		background: #294d4a;
		&:hover {
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		position: relative;
		text-decoration: none;
		color: $white;
		font-size: 16px;
		font-weight: bold;
		background: url("/images/book-btn-paint.svg");
		background-size: cover;
		background-repeat: no-repeat;
		padding: 0.75rem 2rem 0.5rem 2rem;
		width: fit-content;
		&:hover {
			transform: rotate(-2.55deg);
		}
	}

	// btn-secondary
	&.btn-secondary {
		background: $green-new-2;
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		position: relative;
		text-decoration: none;
		color: $white;
		font-size: 16px;
		font-weight: bold;
		background: url("/images/book-btn-paint-secondary.svg");
		background-size: cover;
		background-repeat: no-repeat;
		padding: 0.75rem 2rem 0.5rem 2rem;
		width: fit-content;

		&:hover {
			transform: rotate(-2.55deg);
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

.price-btn {
	position: relative;
	color: $white;
	font-size: 14px;
	font-weight: 600;
	font-family: $font-family-primary;
	z-index: 2;
	padding: 1rem 2rem;
	&::before {
		content: "";
		background: url("/images/paint.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center;
		height: 110%;
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 50%;
		width: 130%;
		transform: translate(-50%, -50%);
		transition: 0.35s ease;
	}
	&:hover {
		text-decoration: none;
		transform: rotate(-5deg);
		transition: 0.35s;
	}
}
